import { selectFeatureFlagCache } from './featureFlagSlice';
import { useAppSelector } from './hooks';
export var FeatureFlags;
(function (FeatureFlags) {
    FeatureFlags["REMEMBER_ORG"] = "REMEMBER_ORG";
    FeatureFlags["FF_PIQ_ACCESS_CONTROL"] = "FF_PIQ_ACCESS_CONTROL";
    FeatureFlags["FF_PHOTO_HOVER"] = "FF_PHOTO_HOVER";
})(FeatureFlags || (FeatureFlags = {}));
export const useFeatureFlag = (flag) => {
    var _a;
    const featureFlagCache = useAppSelector(selectFeatureFlagCache);
    const foundFlag = featureFlagCache.find((ff) => ff.flag === flag);
    return (_a = foundFlag === null || foundFlag === void 0 ? void 0 : foundFlag.isEnabled) !== null && _a !== void 0 ? _a : false;
};
