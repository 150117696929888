import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Flex, List, ListItem, Text, useDisclosure, } from '@chakra-ui/react';
import { Popover } from '@frontend/design-system/components/Popover/Popover';
import React, { useEffect, useRef } from 'react';
import { BellIcon } from '../../config/icons';
import theme from '../../config/theme';
export const ScenarioPlanViolations = ({ hasRelatedProjects, relationshipViolations, }) => {
    const violationsPopover = useDisclosure();
    const popoverRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                violationsPopover === null || violationsPopover === void 0 ? void 0 : violationsPopover.onClose();
            }
        };
        if (violationsPopover === null || violationsPopover === void 0 ? void 0 : violationsPopover.isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [violationsPopover === null || violationsPopover === void 0 ? void 0 : violationsPopover.isOpen]);
    const renderRelationshipViolations = () => {
        if (relationshipViolations.length > 0) {
            return (_jsx(List, Object.assign({ maxHeight: '280px', overflowY: 'auto', spacing: 2, padding: '17px 13px' }, { children: relationshipViolations.map((violation, index) => (_jsxs(_Fragment, { children: [_jsx(ListItem, Object.assign({ fontWeight: 500, fontSize: '10px' }, { children: violation }), index), _jsx(Divider, {})] }))) })));
        }
        else if (!hasRelatedProjects) {
            return (_jsx(Text, Object.assign({ fontWeight: 'medium', fontSize: '10px' }, { children: "No relationships defined in this scenario. Go to myProjects to create associations." })));
        }
        else {
            return (_jsx(Text, Object.assign({ fontWeight: 'medium', fontSize: '10px' }, { children: "No alarms exist for this scenario." })));
        }
    };
    return (_jsx(Flex, Object.assign({ h: '62px', alignItems: 'flex-end' }, { children: _jsx(Popover, Object.assign({ content: renderRelationshipViolations(), trigger: 'click', customStyles: {
                borderRadius: '2px',
                boxShadow: `0px 0px 4px 0px ${theme.colors.base.black}40`,
                maxWidth: '550px',
            }, disclosure: violationsPopover, popoverRef: popoverRef }, { children: _jsx(Flex, Object.assign({ cursor: 'pointer', width: '36px', height: '36px', alignItems: 'center', justifyContent: 'center', onClick: () => {
                    if (!violationsPopover.isOpen) {
                        violationsPopover.onOpen();
                    }
                } }, { children: _jsx(BellIcon, { fill: relationshipViolations.length > 0
                        ? theme.colors.ui.danger
                        : theme.colors.secondaryDarkGray[800], width: '16px', height: '36px', "data-testid": relationshipViolations.length > 0
                        ? 'bell-alarm-icon'
                        : 'bell-default-icon' }) })) })) })));
};
