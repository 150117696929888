import { environment } from './environment';
export const Paths = {
    HOME: '/',
    LOGOUT: '/logout',
    SCENARIO_PLANNER: '/scenario-planner',
    SCENARIO_PLANNER_DYNAMIC: '/scenario-planner/:oid',
    PROJECTS: '/projects',
    PROJECTS_DYNAMIC: '/projects/:oid',
    ASSETS: '/myassets',
    ASSETS_DYNAMIC: '/myassets/:oid',
    SUPPORT: '/support',
};
export const ExternalPaths = {
    getFCAUrl: () => `${environment.fcaUrl}/organizations`,
    getFCAOrgUrl: (oid) => `${environment.fcaUrl}/organizations/${oid}/sites`,
    getMyGalleryUrl: () => `${environment.fcaUrl}/gallery`,
    getMyOrgGalleryUrl: (oid) => `${environment.fcaUrl}/gallery/${oid}`,
    getMyProjectsUrl: () => '/projects',
    getMyOrgProjectsUrl: (oid) => oid ? Paths.PROJECTS_DYNAMIC.replace(':oid', oid) : Paths.PROJECTS,
    getMyScenariosUrl: () => '/scenario-planner',
    getMyOrgScenariosUrl: (oid) => oid
        ? Paths.SCENARIO_PLANNER_DYNAMIC.replace(':oid', oid)
        : Paths.SCENARIO_PLANNER,
    getMyOpportunitiesUrl: (oid) => `${environment.rootPathUrl}/prioritize/opportunities/${oid}/facilities`,
    getMyAssetsUrl: () => '/myassets',
    getMyOrgAssetsUrl: (oid) => oid ? Paths.ASSETS_DYNAMIC.replace(':oid', oid) : Paths.ASSETS,
    getReportOrgs: () => `${environment.msiqUrl}`,
    getSystemAdminUrl: () => `${environment.msiqUrl}/systemAdmin`,
    getOrgSettingsUrl: (oid) => `${environment.msiqUrl}/organizationDetails/${oid}`,
    getOneReportUrl: (oid, wid, rid) => `${environment.msiqUrl}/reports/${oid}/${wid}/${rid}`,
    getReportUrl: (oid, rid) => `${environment.msiqUrl}/report/${oid}/${rid}`,
    getUserProfile: () => `${environment.msiqUrl}/userProfile`,
    getReportsUrl: (orgId) => `${environment.msiqUrl}/organizationPreview/${orgId}`,
    getOrgProfileUrl: (orgId) => `${environment.msiqUrl}/organizationDetails/${orgId}`,
};
