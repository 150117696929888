var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@chakra-ui/react';
import React from 'react';
import { IoMdDownload } from 'react-icons/io';
import { variants } from '../../theme/theme';
const downloadFile = (file) => {
    const href = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = href;
    link.download = file.name;
    link.click();
    URL.revokeObjectURL(href);
};
export const FileDownloadButton = ({ fetchFile, buttonName, }) => {
    const onClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const file = yield fetchFile();
        if (file) {
            downloadFile(file);
        }
    });
    return (_jsx(Button, Object.assign({ "aria-label": 'file-download-button', variant: variants.blueOutlineBtn, leftIcon: _jsx(IoMdDownload, {}), onClick: onClick }, { children: buttonName })));
};
