var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Center, Flex, Spinner } from '@chakra-ui/react';
import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { PlannerSettingsProvider } from '@frontend/domain/contexts/Settings/PlannerSettingsProvider';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { authConfig } from './authConfig';
import { AuthenticationGuard } from './components/auth/AuthenticationGuard';
import { appColors } from './config/constants';
import { Paths } from './config/paths';
import './config/style.css';
import { ProjectsProvider } from './contexts/Projects/ProjectsProvider';
import { MyAssetsPage } from './pages/assets/MyAssetsPage';
import { Logout } from './pages/logout/Logout';
import { NoAssociatedData } from './pages/NoAssociatedData';
import { Projects } from './pages/projects/Projects';
import ScenarioPage from './pages/scenarios/ScenarioPage';
import { SupportRedirect } from './pages/SupportRedirect';
import { apiService, platformApiService } from './services';
import { fetchFeatureFlags } from './store/featureFlagSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { selectSelectedOrg } from './store/orgSlice';
import { fetchUser, selectUser } from './store/userSlice';
const App = () => {
    const dispatch = useAppDispatch();
    const { setToken, isReady } = useDependencies();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const userCurrent = useAppSelector(selectUser);
    const orgCurrent = useAppSelector(selectSelectedOrg);
    useEffect(() => {
        if (isAuthenticated && (user === null || user === void 0 ? void 0 : user.email)) {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: authConfig.authorizationParams.platformAudience,
                    scope: 'email read:current_user update:current_user_metadata',
                },
            }).then((token) => {
                setToken(token);
            });
            apiService.setTokenRetrievalFunction(() => __awaiter(void 0, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            platformApiService.setTokenRetrievalFunction(() => __awaiter(void 0, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            dispatch(fetchUser());
        }
    }, [isAuthenticated, user]);
    useEffect(() => {
        dispatch(fetchFeatureFlags());
    }, []);
    const renderRoutes = () => {
        if (!isReady || (userCurrent === null || userCurrent === void 0 ? void 0 : userCurrent.id) === 'null') {
            return (_jsx(Route, { path: '*', element: _jsx(AuthenticationGuard, { component: () => (_jsx(Center, Object.assign({ w: '100vw', h: '100vh' }, { children: _jsx(Spinner, { color: appColors.PRIM_BLUE }) }))) }) }));
        }
        else if (userCurrent && isReady) {
            return (_jsxs(_Fragment, { children: [_jsx(Route, { path: Paths.HOME, element: _jsx(Navigate, { to: Paths.SCENARIO_PLANNER }) }), _jsxs(Route, Object.assign({ path: Paths.PROJECTS }, { children: [_jsx(Route, { path: '', element: _jsx(AuthenticationGuard, { component: Projects }) }), _jsx(Route, { path: ':oid', element: _jsx(AuthenticationGuard, { component: Projects }) })] })), _jsxs(Route, Object.assign({ path: Paths.SCENARIO_PLANNER }, { children: [_jsx(Route, { path: '', element: _jsx(AuthenticationGuard, { component: ScenarioPage }) }), _jsx(Route, { path: ':oid', element: _jsx(AuthenticationGuard, { component: ScenarioPage }) })] })), _jsxs(Route, Object.assign({ path: Paths.ASSETS }, { children: [_jsx(Route, { path: '', element: _jsx(AuthenticationGuard, { component: MyAssetsPage }) }), _jsx(Route, { path: ':oid', element: _jsx(AuthenticationGuard, { component: MyAssetsPage }) })] })), _jsx(Route, { path: Paths.SUPPORT, element: _jsx(AuthenticationGuard, { component: SupportRedirect }) })] }));
        }
        else {
            return (_jsx(Route, { path: '*', element: _jsx(AuthenticationGuard, { component: NoAssociatedData }) }));
        }
    };
    return (_jsx(Flex, Object.assign({ className: 'App', w: '100%', h: '100vh' }, { children: _jsx(_Fragment, { children: _jsx(PlannerSettingsProvider, Object.assign({ orgIdentity: orgCurrent === null || orgCurrent === void 0 ? void 0 : orgCurrent.identity }, { children: _jsx(ProjectsProvider, Object.assign({ org: orgCurrent }, { children: _jsx(Routes, { children: _jsxs(_Fragment, { children: [_jsx(Route, { path: Paths.LOGOUT, element: _jsx(Logout, {}) }), renderRoutes()] }) }) })) })) }) })));
};
export default App;
