import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, } from '@chakra-ui/react';
import React from 'react';
import { useAppSelector } from '../../store/hooks';
import { scenarioCanBeEditedBy, selectOrgRole } from '../../store/userSlice';
import SelectProjectsModal from './selectProjectsModal/SelectProjectsModal';
import { ZoomYearContainer } from './ZoomYearContainer';
export const ZoomYearTabs = ({ fundingTypes, isEscalated, scheduledProjects, unscheduledProjects, updatePlan, setZoomYear, years, zoomYear, addProjects, removeProject, scenarioId, scenarioIsLocked, escalationFactor, scenarioStartYear, fetchScenarioPlan, }) => {
    const orgRole = useAppSelector(selectOrgRole);
    const canEdit = scenarioCanBeEditedBy(orgRole);
    const { isOpen: isSelectProjectsModalOpen, onOpen: onSelectProjectsModalOpen, onClose: onSelectProjectsModalClose, } = useDisclosure();
    return (_jsxs(Tabs, Object.assign({ borderRadius: '2px', w: '100%', variant: 'enclosed', h: '100%', defaultIndex: years.indexOf(zoomYear), overflowY: 'hidden', display: 'flex', flexDirection: 'column' }, { children: [_jsx(TabList, Object.assign({ w: 'fit-content' }, { children: years.map((year) => (_jsxs(Tab, Object.assign({ _selected: {
                        bg: '#E6E7E9',
                    }, fontWeight: '600', fontSize: '14px', borderRadius: '2px', onClick: () => {
                        if (zoomYear === year) {
                            setZoomYear(undefined);
                        }
                        else {
                            setZoomYear(year);
                        }
                    } }, { children: [year, year === zoomYear && !scenarioIsLocked && canEdit && (_jsx(AddIcon, { "data-testid": 'add-project-icon', onClick: (e) => {
                                e.stopPropagation();
                                onSelectProjectsModalOpen();
                            }, ml: '20px' }))] }), year))) })), _jsx(TabPanels, Object.assign({ h: '100%', bg: '#E6E7E9', w: '100%', position: 'sticky', left: '0' }, { children: years.map((year) => (_jsx(TabPanel, Object.assign({ h: '100%', w: '100%', overflowY: 'scroll' }, { children: _jsx(ZoomYearContainer, { fundingTypes: fundingTypes, isEscalated: isEscalated, scheduledProjects: scheduledProjects, updatePlan: updatePlan, year: year, zoomYear: zoomYear, scenarioId: scenarioId, scenarioIsLocked: scenarioIsLocked, removeProject: removeProject, fetchScenarioPlan: fetchScenarioPlan }) }), year))) })), _jsx(SelectProjectsModal, { isOpen: isSelectProjectsModalOpen, onClose: onSelectProjectsModalClose, addProjects: addProjects, unscheduledProjects: unscheduledProjects, scenarioId: scenarioId, year: zoomYear, isEscalated: isEscalated, escalationFactor: escalationFactor, scenarioStartYear: scenarioStartYear })] })));
};
