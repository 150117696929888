import { jsx as _jsx } from "react/jsx-runtime";
import { List, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { appColors } from '../../config/constants';
import { ProjectCardItem } from './ProjectCard/ProjectCardItem';
export const ZoomYearContainer = ({ fundingTypes, isEscalated, scheduledProjects, updatePlan, removeProject, year, zoomYear, scenarioId, scenarioIsLocked, fetchScenarioPlan, }) => {
    const filterProjectsByYear = scheduledProjects && scheduledProjects.length > 0
        ? scheduledProjects.filter((project) => project.planYear === year)
        : [];
    return (_jsx(Stack, Object.assign({ position: 'initial', width: '100%', id: year.toString(), h: '100%', display: 'block', p: '5px', minW: '17em' }, { children: _jsx(List, Object.assign({ display: filterProjectsByYear.length > 0 ? 'grid' : 'flex', gridTemplateColumns: 'repeat(5, minmax(0, 1fr))', gridAutoRows: 'min-content', borderWidth: '1px', borderColor: '#E6E7E9', boxSizing: 'border-box', p: '2px', borderRadius: '2px', h: '89%', overflowY: 'auto' }, { children: filterProjectsByYear.length > 0 ? (filterProjectsByYear.map((project) => (_jsx(ProjectCardItem, { fundingTypes: fundingTypes, isEscalated: isEscalated, project: project, updatePlan: updatePlan, removeProject: removeProject, zoomYear: zoomYear, scenarioId: scenarioId, scenarioIsLocked: scenarioIsLocked, fetchScenarioPlan: fetchScenarioPlan }, project.projectSummary.identity)))) : (_jsx(Text, Object.assign({ w: '100%', fontSize: '12px', color: appColors.SEC_DARK_GRAY, align: 'center', mt: '5px' }, { children: "To add projects to this year, click '+'" }))) })) })));
};
