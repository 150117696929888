import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Select, Text } from '@chakra-ui/react';
import { appColors } from '@frontend/design-system/theme/constants';
import React from 'react';
import { MdArrowDropDown } from 'react-icons/md';
export const FundingTypeDropdown = ({ currentFundingTypeId, fundingTypes, onChangeFundingType, scenarioIsLocked, currentFundingTypeName, }) => {
    return (_jsx(_Fragment, { children: scenarioIsLocked ? (_jsx(Text, Object.assign({ "data-testid": 'scenario-project-funding-type', fontSize: '14px', color: appColors.SEC_DARK_GRAY }, { children: currentFundingTypeName }))) : (_jsx(Select, Object.assign({ "data-testid": 'scenario-project-funding-type-dropdown', variant: 'unstyled', value: currentFundingTypeId, onChange: (e) => {
                onChangeFundingType(e.target.value);
            }, icon: _jsx(MdArrowDropDown, {}), border: 'none', p: '0px' }, { children: fundingTypes &&
                (fundingTypes === null || fundingTypes === void 0 ? void 0 : fundingTypes.map((funding) => {
                    return (_jsx("option", Object.assign({ value: funding.identity }, { children: funding.name }), funding.identity));
                })) }))) }));
};
