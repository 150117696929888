import { Apps } from '../objects/CPBaseInterfaces';
import { OrganizationRole, SystemRole } from '../objects/UserData';
export const hasAccess = (userCurrent, orgCurrent, orgApps) => {
    var _a, _b;
    if (!userCurrent || userCurrent.id === 'null') {
        return false;
    }
    const apps = userCurrent.apps;
    const userCurrentOrgRole = (_b = (_a = userCurrent.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((orgRole) => (orgCurrent === null || orgCurrent === void 0 ? void 0 : orgCurrent.id) === orgRole.organizationId)) === null || _b === void 0 ? void 0 : _b.role;
    if (SystemRole.SUPER_ADMIN === userCurrent.systemRole ||
        SystemRole.ORG_APPROVER === userCurrent.systemRole ||
        OrganizationRole.LOCAL_ADMIN === userCurrentOrgRole ||
        OrganizationRole.CLIENT_CONCIERGE === userCurrentOrgRole) {
        return !!(orgApps === null || orgApps === void 0 ? void 0 : orgApps.find((orgApp) => Apps.PLANNER === orgApp.app));
    }
    return !!(apps === null || apps === void 0 ? void 0 : apps.find((app) => Apps.PLANNER === app.app && orgCurrent.id === app.organizationId));
};
