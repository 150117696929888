var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronRightIcon, DeleteIcon } from '@chakra-ui/icons';
import { Box, HStack, PopoverTrigger, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import { Popover } from '@frontend/design-system/components/Popover/Popover';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { BiSolidImageAlt } from 'react-icons/bi';
import { MdRemoveRedEye } from 'react-icons/md';
import { PopoverPhotos } from '../../../components/overlay/PopoverPhotos';
import { appColors } from '../../../config/constants';
import { getPriorityInfo, getProjectIconPriorityColor, } from '../../../config/CPHelperFunctions';
import theme from '../../../config/theme';
import { getProjectPhotos } from '../../../services/api/projectAPI';
import { useAppSelector } from '../../../store/hooks';
import { projectCanBeEditedBy, selectOrgRole } from '../../../store/userSlice';
import { ProjectDetailsModal } from '../scenarioPage/ProjectDetailsModal';
import { EstimatedBudget } from './EstimatedBudget';
import { FundingTypeDropdown } from './FundingTypeDropdown';
export const ProjectCardItem = ({ fundingTypes, isEscalated, project, updatePlan, removeProject, zoomYear, years, scenarioId, scenarioIsLocked, fetchScenarioPlan, }) => {
    const [photos, setPhotos] = useState([]);
    const [expandLeftBorder, setExpandLeftBorder] = useState(false);
    const iconRef = useRef(null);
    const orgRole = useAppSelector(selectOrgRole);
    const canEdit = projectCanBeEditedBy(orgRole);
    const { isOpen: isProjectDetailsModalOpen, onOpen: onOpenProjectDetailsModal, onClose: onCloseProjectDetailsModal, } = useDisclosure();
    const [, drag] = useDrag(() => {
        return {
            type: 'projectCardItem',
            item: { project },
        };
    });
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (iconRef.current && !iconRef.current.contains(event.target)) {
                setExpandLeftBorder(false);
            }
        };
        if (expandLeftBorder) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [expandLeftBorder]);
    const getPhotos = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const photos = yield getProjectPhotos(project.projectSummary.identity, 10);
            if (photos && photos.length > 0) {
                setPhotos(photos);
            }
        }
        catch (error) {
            console.error('Error fetching project photos:', error);
        }
    });
    const onChangeFundingType = (value) => {
        updatePlan(scenarioId, project, parseInt(value));
    };
    return (_jsx(PopoverPhotos, Object.assign({ photos: photos, emptyText: 'To see photos of this project, add asset photos in the Organize application.', hasOwnTrigger: true }, { children: _jsxs(VStack, Object.assign({ h: 'fit-content', bg: '#FFFFFF', boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)', boxSize: 'border-box', borderRadius: '2px', flexDirection: 'column', alignItems: 'baseline', mb: '20px !important', mr: zoomYear || (years && years.length < 4) ? '20px !important' : '', marginInlineStart: '0px !important', position: 'relative', ref: (node) => canEdit &&
                !zoomYear &&
                years &&
                (years === null || years === void 0 ? void 0 : years.length) > 1 &&
                !scenarioIsLocked
                ? drag(node)
                : node }, { children: [project && getPriorityInfo(project.projectSummary.priorityScore) && (_jsx(Box, Object.assign({ position: 'absolute', borderLeftRadius: '2px', left: '0', top: '0', bottom: '0', w: expandLeftBorder ? '18px' : '12px', bg: getPriorityInfo(project.projectSummary.priorityScore).color, display: 'flex', alignItems: 'center', justifyContent: 'center' }, { children: !scenarioIsLocked &&
                        canEdit &&
                        (expandLeftBorder ? (_jsx(Box, Object.assign({ ref: iconRef }, { children: _jsx(DeleteIcon, { "data-testid": 'delete-icon', cursor: scenarioIsLocked || !canEdit ? '' : 'pointer', onClick: () => {
                                    if (!scenarioIsLocked && canEdit) {
                                        if (expandLeftBorder) {
                                            removeProject(project);
                                        }
                                    }
                                }, color: getProjectIconPriorityColor(project) }) }))) : (_jsx(ChevronRightIcon, { "data-testid": 'chevron-right-icon', cursor: scenarioIsLocked || !canEdit ? '' : 'pointer', onClick: () => {
                                if (!scenarioIsLocked && canEdit) {
                                    setExpandLeftBorder(true);
                                }
                            }, color: getProjectIconPriorityColor(project) }))) }))), _jsxs(VStack, Object.assign({ align: 'flex-start', pl: '30px', w: '100%' }, { children: [_jsxs(HStack, Object.assign({ justifyContent: 'space-between', w: '100%' }, { children: [_jsx(Box, Object.assign({ w: '75%' }, { children: _jsx(Popover, Object.assign({ content: _jsxs(Box, { children: [_jsx(Text, Object.assign({ fontSize: '10px' }, { children: project.projectSummary.facilityName })), _jsx(Text, Object.assign({ fontWeight: 700, fontSize: '10px' }, { children: project.projectSummary.name }))] }), customStyles: {
                                            borderRadius: '2px',
                                            boxShadow: `0px 0px 4px 0px ${theme.colors.base.black}40`,
                                            maxWidth: '135px',
                                        } }, { children: _jsx(Text, Object.assign({ as: 'h4', style: {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }, color: scenarioIsLocked ? appColors.SEC_DARK_GRAY : undefined }, { children: project.projectSummary.name })) })) })), _jsx(Box, { children: _jsx(PopoverTrigger, { children: _jsx(Box, Object.assign({ pr: '12px', _hover: { cursor: 'pointer' } }, { children: _jsx(BiSolidImageAlt, { onMouseOver: () => getPhotos(), onClick: () => getPhotos(), color: scenarioIsLocked
                                                    ? appColors.SEC_DARK_GRAY
                                                    : appColors.PRIM_BLUE }) })) }) })] })), _jsx(FundingTypeDropdown, { currentFundingTypeId: project.projectSummary.fundingTypeId, fundingTypes: fundingTypes, onChangeFundingType: onChangeFundingType, scenarioIsLocked: scenarioIsLocked, currentFundingTypeName: project.projectSummary.fundingTypeName }), _jsxs(HStack, Object.assign({ w: '100%', justifyContent: 'space-between' }, { children: [_jsx(EstimatedBudget, { estimatedBudget: project.projectSummary.estimatedBudget, escalatedBudget: project.projectSummary.escalatedBudget, isEscalated: isEscalated, scenarioIsLocked: scenarioIsLocked }), _jsx(Box, Object.assign({ pr: '12px', _hover: { cursor: 'pointer' } }, { children: _jsx(MdRemoveRedEye, { onClick: onOpenProjectDetailsModal, color: scenarioIsLocked
                                            ? appColors.SEC_DARK_GRAY
                                            : appColors.PRIM_BLUE }) }))] }))] })), _jsx(ProjectDetailsModal, { isOpen: isProjectDetailsModalOpen, onClose: onCloseProjectDetailsModal, projectIdentity: project.projectSummary.identity, fetchScenarioPlan: fetchScenarioPlan })] })) })));
};
