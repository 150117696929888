var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertCreateScenarioToScenarioConfigurationRequest, convertICreateTaxImpactToTaxImpactSetRequest, convertResponseToScenario, convertScenarioSummaryResponseToIScenarioSummary, convertTaxImpactDetailResponseToITaxImpactDetails, } from '../models/Scenario/ScenarioConversions';
export const useScenarioService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchScenarioList = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.scenario.getApiV1ScenarioList(orgIdentity), {
            error: { label: 'Error occurred retrieving Scenario List' },
            success: { enabled: false },
        })
            .then((res) => res.map((r) => convertScenarioSummaryResponseToIScenarioSummary(r)))
            .catch(() => undefined);
    });
    const fetchScenario = (scenarioIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.scenario.getApiV1ScenarioLoad(scenarioIdentity), {
            error: { label: 'Error occurred retrieving the Scenario' },
            success: { enabled: false },
        })
            .then((res) => {
            return convertResponseToScenario(res);
        })
            .catch(() => undefined);
    });
    const createScenario = (scenarioRequest) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.scenario.postApiV1ScenarioConfigure(convertCreateScenarioToScenarioConfigurationRequest(scenarioRequest)), {
            error: { label: 'Error occurred creating the Scenario' },
            success: { enabled: false },
        }).then((res) => {
            return convertResponseToScenario(res);
        });
    });
    const deleteScenario = (scenarioIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.scenario.deleteApiV1ScenarioRemove(scenarioIdentity), {
            error: {
                label: 'Error occured deleting Scenario',
            },
            success: {
                enabled: true,
                label: 'Successfully deleted Scenario',
            },
        }).then(() => {
            return;
        });
    });
    const setTaxImpact = (taxImpactForm) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.scenario.postApiV1ScenarioSettaximpact(convertICreateTaxImpactToTaxImpactSetRequest(taxImpactForm)), {
            error: { label: 'Error occurred creating the Tax Impact' },
            success: { label: 'Tax Impact Saved', enabled: true },
        }).then((res) => {
            return convertTaxImpactDetailResponseToITaxImpactDetails(res);
        });
    });
    return {
        scenario: {
            fetchList: fetchScenarioList,
            fetchScenario: fetchScenario,
            createScenario: createScenario,
            deleteScenario: deleteScenario,
            setTaxImpact: setTaxImpact,
        },
    };
};
