import { jsx as _jsx } from "react/jsx-runtime";
import { HorizontalInfoCard, } from '@frontend/design-system/components/DataDisplay/HorizontalInfoCard';
import { getCurrencyNotation } from '../../../../config/CPHelperFunctions';
export const DollarSavingCard = ({ saving }) => {
    const stringSaving = getCurrencyNotation(parseInt(saving.saving.replace(/,/g, '')));
    const stringDuration = `${saving.duration} ${saving.duration === '1' ? 'Year' : 'Years'}`;
    const details = [
        { label: 'Duration', info: stringDuration },
        { label: 'Type of saving', info: saving.type },
        { label: 'Notes', info: saving.notes },
    ];
    return _jsx(HorizontalInfoCard, { header: stringSaving, details: details });
};
