import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, Select, Text, VStack } from '@chakra-ui/react';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { validateMinMax } from '@frontend/domain/formValidations/RuleValidation';
import { Controller } from 'react-hook-form';
import { convertToCurrency, getCurrencyNotation, } from '../../../../config/CPHelperFunctions';
import { TaxImpactText } from './TaxImpactText';
import { TaxImpactValueText } from './TaxImpactValueText';
export const MonthlyAnnualInput = ({ taxImpactExists, formContext, dropdownFormKey, currencyFormKey, beforeText, }) => {
    const { control, formState: { errors }, watch, } = formContext;
    const dropdownValue = watch(dropdownFormKey);
    const currencyValue = watch(currencyFormKey);
    const options = [];
    for (let i = 100000; i <= 400000; i += 25000) {
        options.push(_jsx("option", Object.assign({ value: `${i}` }, { children: getCurrencyNotation(i, false) }), i));
    }
    return taxImpactExists && currencyValue && dropdownValue ? (_jsxs(VStack, Object.assign({ spacing: 0.5, alignSelf: 'flex-start', "aria-label": `${beforeText}-tax-impact-values` }, { children: [_jsx(TaxImpactText, { text: `${beforeText} tax impact on ${getCurrencyNotation(parseFloat(dropdownValue), false)} home` }), _jsx(TaxImpactValueText, { text: `${convertToCurrency(currencyValue, true, true)}` })] }))) : (_jsxs(VStack, Object.assign({ w: '100%', spacing: 1, "aria-label": `${beforeText}-tax-impact-form` }, { children: [_jsxs(HStack, Object.assign({ w: '100%', spacing: 1 }, { children: [_jsx(TaxImpactText, { text: `${beforeText} tax impact on` }), _jsx(Controller, { control: control, name: dropdownFormKey, rules: { required: true }, render: ({ field }) => (_jsx(Box, { children: _jsx(Select, Object.assign({ "aria-label": dropdownFormKey, h: '25px', w: '100px', placeholder: 'Select', onChange: (e) => {
                                    if (!(e && e.target.value) || e.target.value === '') {
                                        field.onChange(undefined);
                                    }
                                    field.onChange(e);
                                } }, { children: options })) })) }), _jsx(TaxImpactText, { text: 'home', requiredAfter: true })] })), _jsx(Controller, { control: control, name: currencyFormKey, rules: {
                    required: true,
                    validate: (value) => {
                        return validateMinMax(value, 0, 9000);
                    },
                }, render: ({ field }) => {
                    var _a;
                    return (_jsxs(Box, Object.assign({ w: '100%', "aria-label": currencyFormKey }, { children: [_jsx(CurrencyInput, { accessibilityLabel: currencyFormKey, data: field.value, required: true, onInputChange: (e) => {
                                    if (!(e && e.target.value) || e.target.value === '') {
                                        field.onChange(undefined);
                                    }
                                    field.onChange(e);
                                }, placeholder: 'Type here...' }), errors[currencyFormKey] && (_jsx(Text, Object.assign({ alignSelf: 'start', color: 'red', fontSize: '13px' }, { children: (_a = errors[currencyFormKey]) === null || _a === void 0 ? void 0 : _a.message })))] })));
                } })] })));
};
