var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { ButtonGroup, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import { DeleteModal } from '@frontend/design-system/components/DeleteModal/DeleteModal';
import { Tooltip } from '@frontend/design-system/components/Tooltip/Tooltip';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { MdModeEditOutline } from 'react-icons/md';
import { TaxIcon } from '../../config/icons';
import { variants } from '../../config/theme';
import { OrganizationRole } from '../../objects/UserData';
import { useAppSelector } from '../../store/hooks';
import { selectOrgRole, userIsCCPlus } from '../../store/userSlice';
import { ScenarioModal } from './ScenarioModal';
import { TaxImpactModal } from './taxImpact/TaxImpactModal';
import { UnlockScenarioWarningModal } from './UnlockScenarioWarningModal';
export const ScenarioPlanButtons = ({ scenarioIsLocked, selectedScenarioId, toggleLockScenario, editScenario, deleteScenario, taxImpact, createTaxImpact, fetchTaxImpactCSV, }) => {
    const orgRole = useAppSelector(selectOrgRole);
    const isCCPlusUser = useAppSelector(userIsCCPlus);
    const scenarioDeleteDisclosure = useDisclosure();
    const scenarioEditDisclosure = useDisclosure();
    const taxImpactDisclosure = useDisclosure();
    const unlockScenarioWarningDisclosure = useDisclosure();
    const onDeleteScenario = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedScenarioId) {
            yield deleteScenario(selectedScenarioId);
        }
    });
    const deleteScenarioProps = {
        ariaLabel: 'delete-scenario',
        icon: _jsx(BiSolidTrashAlt, {}),
        action: () => {
            scenarioDeleteDisclosure.onOpen();
        },
        variant: variants.redOutlineBtn,
        tooltipLabel: 'Delete',
        buttonIsVisible: selectedScenarioId !== undefined &&
            !scenarioIsLocked &&
            orgRole !== OrganizationRole.VISITOR,
    };
    const editScenarioProps = {
        ariaLabel: 'edit-scenario',
        icon: _jsx(MdModeEditOutline, {}),
        action: () => {
            scenarioEditDisclosure.onOpen();
        },
        variant: variants.blueOutlineBtn,
        tooltipLabel: 'Edit',
        buttonIsVisible: selectedScenarioId !== undefined &&
            !scenarioIsLocked &&
            orgRole !== OrganizationRole.VISITOR,
    };
    const taxImpactProps = {
        ariaLabel: 'scenario-tax-impact',
        icon: _jsx(TaxIcon, { color: 'white' }),
        action: () => {
            taxImpactDisclosure.onOpen();
        },
        variant: variants.blueBtn,
        tooltipLabel: 'Tax Impact',
        buttonIsVisible: selectedScenarioId !== undefined &&
            scenarioIsLocked &&
            (orgRole === OrganizationRole.LOCAL_ADMIN ||
                orgRole === OrganizationRole.CLIENT_CONCIERGE ||
                ((orgRole === OrganizationRole.MEMBER ||
                    orgRole === OrganizationRole.VISITOR) &&
                    taxImpact !== undefined)),
    };
    const lockUnlockScenarioProps = {
        ariaLabel: `${scenarioIsLocked ? 'unlock-scenario' : 'lock-scenario'}`,
        icon: scenarioIsLocked ? _jsx(UnlockIcon, {}) : _jsx(LockIcon, {}),
        action: () => {
            if (scenarioIsLocked) {
                unlockScenarioWarningDisclosure.onOpen();
            }
            else {
                toggleLockScenario(true);
            }
        },
        variant: variants.blueBtn,
        tooltipLabel: `${scenarioIsLocked ? 'Unlock' : 'Lock'}`,
        buttonIsVisible: selectedScenarioId !== undefined &&
            (orgRole === OrganizationRole.LOCAL_ADMIN ||
                orgRole === OrganizationRole.CLIENT_CONCIERGE),
    };
    const renderButton = (props) => (_jsx(_Fragment, { children: props.buttonIsVisible && (_jsx(Tooltip, { label: props.tooltipLabel, icon: _jsx(IconButton, { "aria-label": props.ariaLabel, icon: props.icon, onClick: props.action, variant: props.variant, w: '56px', h: '36px' }), props: {
                children: null,
                placement: 'bottom-end',
                hasArrow: true,
                bg: 'white',
                color: '#2E3532',
                boxShadow: '0px 0px 4px 0px #00000040',
                border: '1px solid #D6D7DB',
                arrowShadowColor: '#D6D7DB',
                arrowSize: 20,
            } })) }));
    return (_jsxs(_Fragment, { children: [_jsx(Flex, Object.assign({ h: '100%', justifyContent: 'flex-end', flexDirection: 'column' }, { children: _jsxs(ButtonGroup, Object.assign({ spacing: '20px' }, { children: [renderButton(deleteScenarioProps), renderButton(editScenarioProps), renderButton(taxImpactProps), renderButton(lockUnlockScenarioProps)] })) })), _jsx(DeleteModal, { disclosure: scenarioDeleteDisclosure, onDelete: onDeleteScenario, text: 'You are about to remove a scenario. A deleted scenario cannot be recovered.' }), _jsx(ScenarioModal, { isOpen: scenarioEditDisclosure.isOpen, onClose: scenarioEditDisclosure.onClose, fundingTypes: editScenario.orgFundingTypes, isEditModal: true, scenario: editScenario.scenario, upsertScenario: editScenario.upsertScenario, scenarioList: editScenario.scenarioList }), _jsx(TaxImpactModal, { disclosure: taxImpactDisclosure, taxImpact: taxImpact, onCreate: createTaxImpact, orgRole: orgRole, fetchTaxImpactCSV: fetchTaxImpactCSV, userIsCCPlus: isCCPlusUser }), _jsx(UnlockScenarioWarningModal, { isOpen: unlockScenarioWarningDisclosure.isOpen, onClose: unlockScenarioWarningDisclosure.onClose, toggleLockScenario: toggleLockScenario })] }));
};
