import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Heading, List, Stack, Text, useDisclosure } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { appColors } from '../../config/constants';
import { useAppSelector } from '../../store/hooks';
import { scenarioCanBeEditedBy, selectOrgRole } from '../../store/userSlice';
import { ProjectCardItem } from './ProjectCard/ProjectCardItem';
import SelectProjectsModal from './selectProjectsModal/SelectProjectsModal';
export const YearContainer = ({ fundingTypes, isEscalated, scheduledProjects, unscheduledProjects, updatePlan, years, year, zoomYear, setZoomYear, addProjects, removeProject, scenarioId, scenarioIsLocked, escalationFactor, scenarioStartYear, fetchScenarioPlan, }) => {
    const orgRole = useAppSelector(selectOrgRole);
    const canEdit = scenarioCanBeEditedBy(orgRole);
    const { isOpen: isSelectProjectsModalOpen, onOpen: onSelectProjectsModalOpen, onClose: onSelectProjectsModalClose, } = useDisclosure();
    const filterProjectsByYear = scheduledProjects && scheduledProjects.length > 0
        ? scheduledProjects.filter((project) => project.planYear === year)
        : [];
    const dropSpec = useMemo(() => ({
        accept: 'projectCardItem',
        drop: (item) => {
            const project = item.project;
            updatePlan(scenarioId, project, undefined, year);
        },
    }), [scenarioId, year, updatePlan]);
    const [, drop] = useDrop(dropSpec);
    return (_jsxs(Stack, Object.assign({ position: 'initial', width: '100%', id: year.toString(), ref: (node) => {
            return drop(node);
        }, h: '100%', display: 'block', p: '5px', minW: '17em' }, { children: [_jsxs(Heading, Object.assign({ mb: '15px', w: '100%', display: 'flex', justifyContent: 'space-between', cursor: 'pointer', as: 'h3', variant: 'h3-v2', onClick: () => setZoomYear(year) }, { children: [year, !scenarioIsLocked && canEdit && (_jsx(AddIcon, { "data-testid": 'add-project-icon', onClick: (e) => {
                            e.stopPropagation();
                            onSelectProjectsModalOpen();
                        } }))] })), _jsx(List, Object.assign({ display: years.length < 4 ? 'grid' : '', gridTemplateColumns: filterProjectsByYear.length > 0 &&
                    years.length < 4 &&
                    years.length > 1
                    ? 'repeat(2,  minmax(0, 1fr))'
                    : filterProjectsByYear.length > 0 && years.length === 1
                        ? 'repeat(5,  minmax(0, 1fr))'
                        : '', gridAutoRows: 'min-content', ref: (node) => drop(node), borderWidth: '1px', borderColor: '#E6E7E9', bg: '#F2F3F4', boxSizing: 'border-box', p: '2px', borderRadius: '2px', h: '89%', overflowY: 'auto' }, { children: filterProjectsByYear.length > 0 ? (filterProjectsByYear.map((project) => (_jsx(ProjectCardItem, { fundingTypes: fundingTypes, isEscalated: isEscalated, project: project, updatePlan: updatePlan, removeProject: removeProject, years: years, zoomYear: zoomYear, scenarioId: scenarioId, scenarioIsLocked: scenarioIsLocked, fetchScenarioPlan: fetchScenarioPlan }, project.projectSummary.identity)))) : (_jsx(Text, Object.assign({ w: '100%', fontSize: '12px', color: appColors.SEC_DARK_GRAY, align: 'center', mt: '5px' }, { children: "To add projects to this year, click '+'" }))) })), _jsx(SelectProjectsModal, { isOpen: isSelectProjectsModalOpen, onClose: onSelectProjectsModalClose, addProjects: addProjects, unscheduledProjects: unscheduledProjects, scenarioId: scenarioId, year: year, isEscalated: isEscalated, escalationFactor: escalationFactor, scenarioStartYear: scenarioStartYear })] })));
};
