var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Center, Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { ExternalPaths } from '../../config/paths';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectOrgApps, selectSelectedOrg } from '../../store/orgSlice';
import { fetchCreateProjectData, selectCreateProjectData, } from '../../store/projectSlice';
import { selectAccess } from '../../store/userSlice';
import { AccessDenied } from '../AccessDenied';
import { AddProjectModal } from './AddProjectModal/AddProjectModal';
import ProjectsTable from './ProjectsTable';
export const priorityPadding = '0px';
export const Projects = () => {
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const currOrg = useAppSelector(selectSelectedOrg);
    const orgApps = useAppSelector(selectOrgApps);
    const cpAccess = useAppSelector(selectAccess);
    const sites = useAppSelector(selectCreateProjectData);
    const { orgFundingTypes, orgProjectTypes } = usePlannerSettingsContext();
    const { projectList, newProjectAdded, toggleNewProjectAdded, addNewProject } = useProjectsContext();
    const projectDisclosure = useDisclosure();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = (orgId) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setLoading(true);
                // fetches sites list for project creation
                // TODO: move from here to facility selector
                dispatch(fetchCreateProjectData(orgId));
            }
            catch (err) {
                console.error('Failed to fetch project data: ', err);
                toast({
                    title: 'Failed to fetch project data',
                    status: 'error',
                    isClosable: true,
                });
            }
            finally {
                setLoading(false);
            }
        });
        const orgId = params.oid || (currOrg && currOrg.id);
        if (orgId) {
            fetchData(orgId);
        }
    }, [params.oid, currOrg === null || currOrg === void 0 ? void 0 : currOrg.id]);
    useEffect(() => {
        if (location.state && location.state.showModal) {
            projectDisclosure.onOpen();
            // clear state once used to avoid state mutation issues on reload
            window.history.replaceState({}, document.title);
            location.state.showModal = false;
        }
    });
    return (_jsx(PageLayout, Object.assign({ title: 'myProjects', onAddProject: projectDisclosure.onOpen, onAddScenario: () => navigate(ExternalPaths.getMyOrgScenariosUrl(currOrg === null || currOrg === void 0 ? void 0 : currOrg.id), {
            state: { showModal: true },
        }) }, { children: _jsxs(_Fragment, { children: [loading || !currOrg || !orgApps ? (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsx(Spinner, { color: appColors.PRIM_BLUE }) }))) : !cpAccess ? (_jsx(AccessDenied, {})) : (_jsx(ProjectsTable, { projects: projectList, newProjectAdded: newProjectAdded, toggleNewProjectAdded: toggleNewProjectAdded })), _jsx(AddProjectModal, { disclosure: projectDisclosure, sites: sites || [], fundingTypes: orgFundingTypes, projectTypes: orgProjectTypes, onCreate: addNewProject })] }) })));
};
