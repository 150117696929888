import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import Chart from 'react-apexcharts';
export const BarChart = ({ config, containerHeight, containerWidth, data, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const getAvgAnnotation = (config, dataSet) => {
        if (config.showAvg) {
            const sum = dataSet.datasets
                .flatMap((dataset) => dataset.data)
                .reduce((prev, curr) => prev + curr);
            const avg = sum / dataSet.axisCategories.length;
            return config.horizontal
                ? getXAnnotation(avg)
                : getYAnnotation(avg);
        }
        return { xaxis: [], yaxis: [] };
    };
    const getYAnnotation = (avg) => {
        return {
            yaxis: [
                {
                    y: avg,
                    borderColor: '#00E396',
                    label: {
                        borderColor: '#00E396',
                        style: {
                            color: '#fff',
                            background: '#00E396',
                        },
                        text: 'Average',
                    },
                },
            ],
            xaxis: [],
        };
    };
    const getXAnnotation = (avg) => {
        return {
            yaxis: [],
            xaxis: [
                {
                    x: avg,
                    borderColor: '#00E396',
                    label: {
                        borderColor: '#00E396',
                        style: {
                            color: '#fff',
                            background: '#00E396',
                        },
                        text: 'Average',
                    },
                },
            ],
        };
    };
    const hasGroups = data.datasets.length > 1;
    const options = {
        chart: {
            id: 'basic-bar',
            group: config.chartGroup ? config.chartGroup : '',
            stacked: config.stacked ? true : false,
            toolbar: {
                show: false,
            },
        },
        colors: config.datasetColors,
        dataLabels: {
            enabled: false,
            offsetX: config.horizontal ? -10 : 0,
            offsetY: config.horizontal ? 0 : 10,
            style: {
                colors: ['black'],
            },
            formatter: ((_a = config.barLabel) === null || _a === void 0 ? void 0 : _a.format)
                ? (_b = config.barLabel) === null || _b === void 0 ? void 0 : _b.format
                : (val) => val || '',
        },
        legend: {
            customLegendItems: ((_c = config.legend) === null || _c === void 0 ? void 0 : _c.datasetLabels)
                ? config.legend.datasetLabels
                : [],
        },
        plotOptions: {
            bar: {
                // distributed true allows bars to be formatted individually
                // we only want to format bars individually when we are not grouping or stacking datasets
                distributed: data.datasets.length > 1 ? false : true,
                horizontal: config.horizontal ? true : false,
                dataLabels: {
                    position: ((_d = config.barLabel) === null || _d === void 0 ? void 0 : _d.position) || 'top',
                    total: {
                        enabled: hasGroups,
                        offsetY: config.horizontal ? 0 : 15,
                        offsetX: config.horizontal ? -25 : 0,
                        formatter: ((_e = config.barLabel) === null || _e === void 0 ? void 0 : _e.format)
                            ? (_f = config.barLabel) === null || _f === void 0 ? void 0 : _f.format
                            : (val) => val || '',
                    },
                },
            },
        },
        annotations: getAvgAnnotation(config, data),
        tooltip: {
            enabled: ((_g = config.tooltip) === null || _g === void 0 ? void 0 : _g.enabled) ? true : false,
            shared: true,
            intersect: false,
            custom: (_h = config.tooltip) === null || _h === void 0 ? void 0 : _h.custom,
            x: {
                formatter: (_k = (_j = config.formatters) === null || _j === void 0 ? void 0 : _j.tooltip) === null || _k === void 0 ? void 0 : _k.x,
            },
            y: {
                formatter: (_m = (_l = config.formatters) === null || _l === void 0 ? void 0 : _l.tooltip) === null || _m === void 0 ? void 0 : _m.y,
            },
        },
        xaxis: {
            categories: data.axisCategories,
            labels: {
                style: { colors: (_o = config.labelColors) === null || _o === void 0 ? void 0 : _o.xAxis },
                formatter: (_p = config.formatters) === null || _p === void 0 ? void 0 : _p.xAxis,
            },
        },
        yaxis: {
            labels: {
                style: { colors: (_q = config.labelColors) === null || _q === void 0 ? void 0 : _q.yAxis },
                formatter: (_r = config.formatters) === null || _r === void 0 ? void 0 : _r.yAxis,
            },
        },
    };
    const series = data.datasets;
    return (_jsx(Box, Object.assign({ height: containerHeight, width: containerWidth }, { children: _jsx(Chart, { options: options, series: series, type: 'bar', height: containerHeight, width: containerWidth }) })));
};
