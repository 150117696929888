import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, GridItem, IconButton, Text, Tooltip, useToken, } from '@chakra-ui/react';
import { SelectInline } from '@frontend/design-system/components/Inputs/SelectInline';
import { isValidProjectRelationshipType } from '@frontend/domain/models/Projects/ProjectConversions';
import { ProjectRelationshipType, } from '@frontend/domain/models/Projects/ProjectsInterfaces';
import React from 'react';
import { BiSolidTrashAlt } from 'react-icons/bi';
import theme from '../../../../config/theme';
export const AssociatedProjectRow = ({ isEditing, associatedProject, updateItem, removeItem, gridStyle, isRequiredBy, }) => {
    const [darkGrey, blue] = useToken('colors', [
        'secondaryDarkGray',
        'brand.primBlue',
    ]);
    const relationshipTypeOptions = Object.values(ProjectRelationshipType)
        .filter((type) => {
        return (associatedProject.relationshipType ===
            ProjectRelationshipType.REQUIRED_BY ||
            type !== ProjectRelationshipType.REQUIRED_BY);
    })
        .map((type) => ({
        label: type === ProjectRelationshipType.REQUIRED_BY ? 'Required by' : type,
        value: type,
    }));
    return (_jsxs(Grid, Object.assign({}, gridStyle, { "data-testid": 'associated-projects-row' }, { children: [_jsx(GridItem, Object.assign({ colSpan: 1 }, { children: _jsx(Tooltip, Object.assign({ label: associatedProject.projectName, openDelay: 750 }, { children: _jsx(Text, Object.assign({ whiteSpace: 'normal', noOfLines: 2, textOverflow: 'ellipsis' }, { children: associatedProject.projectName })) })) })), isEditing ? (_jsx(GridItem, Object.assign({ colSpan: 2 }, { children: _jsxs(Grid, Object.assign({ templateColumns: '85% 15%', justifyItems: 'center', alignItems: 'center', w: '100%' }, { children: [_jsx(SelectInline, { ariaLabel: 'Associated Project Select', options: relationshipTypeOptions, isDisabled: isRequiredBy, onChange: (relationship) => {
                                if (isValidProjectRelationshipType(relationship)) {
                                    updateItem(relationship);
                                }
                            }, textColor: darkGrey, iconColor: blue, backgroundColor: `${theme.colors.base.white}`, value: associatedProject.relationshipType }), !isRequiredBy && (_jsx(IconButton, { ml: '20px', "aria-label": 'delete project association', onClick: removeItem, variant: 'unstyled', color: blue, icon: _jsx(BiSolidTrashAlt, {}) }))] })) }))) : (_jsx(GridItem, Object.assign({ colSpan: 2 }, { children: _jsx(Text, { children: associatedProject.relationshipType !==
                        ProjectRelationshipType.REQUIRED_BY
                        ? associatedProject.relationshipType
                        : 'Required by' }) })))] })));
};
