var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SystemRole } from '../objects/UserData';
import { FriendlyPriorityCode, priorityColorsCodes } from './constants';
export const getPriorityInfo = (score) => {
    if (score === undefined) {
        return priorityColorsCodes[0];
    }
    // Needed since ceiling of zero will remain 0 and we need an index of 1
    if (score === 0) {
        return priorityColorsCodes[1];
    }
    const scoreFloor = Math.ceil(score);
    const priorInfo = scoreFloor > 5 ? priorityColorsCodes[5] : priorityColorsCodes[scoreFloor];
    return priorInfo;
};
export const getProjectIconPriorityColor = (project) => {
    if (project && getPriorityInfo(project.projectSummary.priorityScore)) {
        if (getPriorityInfo(project.projectSummary.priorityScore).code ===
            FriendlyPriorityCode.NEEDS_ACTION ||
            getPriorityInfo(project.projectSummary.priorityScore).code ===
                FriendlyPriorityCode.HIGH ||
            getPriorityInfo(project.projectSummary.priorityScore).code ===
                FriendlyPriorityCode.IMMEDIATE) {
            return 'black';
        }
        else if (getPriorityInfo(project.projectSummary.priorityScore).code ===
            FriendlyPriorityCode.LONG_RANGE ||
            getPriorityInfo(project.projectSummary.priorityScore).code ===
                FriendlyPriorityCode.LOW ||
            getPriorityInfo(project.projectSummary.priorityScore).code ===
                FriendlyPriorityCode.MODERATE) {
            return 'white';
        }
    }
};
export const getFriendlyPriorityRating = (rating) => {
    const priorityCode = priorityColorsCodes.find((priorities) => priorities.enumCode === rating);
    if (priorityCode) {
        return priorityCode.code;
    }
    else {
        return undefined;
    }
};
// Will not return decimals
// Always rounds up
export const getCurrencyNotation = (num, showCurrencySymbol = true) => {
    const symbol = showCurrencySymbol ? '$' : '';
    if (num <= 999) {
        const options = {};
        if (showCurrencySymbol) {
            options.style = 'currency';
            options.currency = 'USD';
        }
        return `${num.toLocaleString('en-US', options).split('.')[0]}`;
    }
    else if (num <= 999000) {
        const roundByK = parseFloat((num / 1000).toFixed(2));
        return `${symbol}${roundByK}K`;
    }
    else if (num <= 999000000) {
        const roundByM = parseFloat((num / 1000000).toFixed(2));
        return `${symbol}${roundByM}M`;
    }
    else if (num <= 999000000000) {
        const roundByB = parseFloat((num / 1000000000).toFixed(2));
        return `${symbol}${roundByB}B`;
    }
    else {
        const roundByT = parseFloat((num / 1000000000000).toFixed(2));
        return `${symbol}${roundByT}T`;
    }
};
export const convertToCurrency = (num, showCurrencySign = true, showCents = false) => {
    const parsedNum = parseFloat(num);
    const options = {
        minimumFractionDigits: showCents && parsedNum % 1 !== 0 ? 2 : 0,
        maximumFractionDigits: showCents ? 2 : 0,
    };
    if (showCurrencySign) {
        options.style = 'currency';
        options.currency = 'USD';
    }
    const currencyValue = parsedNum.toLocaleString('en-US', options);
    return currencyValue;
};
export const convertStringToNumber = (numString) => {
    return Number(numString.replace(/,/g, ''));
};
export const calcAssetConditionAverage = (assets, showAgeAdjustedCondition) => {
    let sum = 0;
    let divisor = 0;
    assets.forEach((asset) => {
        let condition = undefined;
        if (showAgeAdjustedCondition) {
            condition = asset.ageAdjustedCondition;
        }
        else {
            condition = asset.condition;
        }
        if (condition) {
            sum += condition * asset.quantity;
            divisor += asset.quantity;
        }
    });
    const average = divisor > 0 ? roundToTenth(sum / divisor) : 0;
    return average;
};
export const roundToTenth = (value) => {
    return Math.round(value * 10) / 10;
};
export const doAnyAssetsHaveCondition = (assets, showAgeAdjustedCondition) => {
    return assets.some((asset) => {
        if (showAgeAdjustedCondition) {
            return (asset.ageAdjustedCondition !== null &&
                asset.ageAdjustedCondition !== undefined);
        }
        else {
            return asset.condition !== null && asset.condition !== undefined;
        }
    });
};
export const toSysRoleString = (role) => {
    switch (role) {
        case SystemRole.SUPER_ADMIN:
            return 'Super Admin';
        case SystemRole.ORG_APPROVER:
            return 'Org Approver';
        case SystemRole.USER:
            return 'User';
        default:
            return '';
    }
};
export const calculateBudget = (scenarioProject, scenario, escalationFactor) => {
    if (scenarioProject.project.estBudget) {
        if (!scenario.escalationFactor || !escalationFactor) {
            return scenarioProject.project.estBudget;
        }
        else {
            if (scenarioProject.project.quoteDate) {
                if (scenarioProject.year &&
                    scenarioProject.year >
                        +scenarioProject.project.quoteDate.toString().substring(0, 4)) {
                    const escIndex = scenarioProject.year -
                        +scenarioProject.project.quoteDate.toString().substring(0, 4);
                    return (scenarioProject.project.estBudget *
                        Math.pow(escalationFactor.escalation + 1, escIndex));
                }
                else {
                    return scenarioProject.project.estBudget;
                }
            }
            else {
                if (scenarioProject.year &&
                    scenarioProject.year - scenario.startYear > 0) {
                    return (scenarioProject.project.estBudget *
                        Math.pow(escalationFactor.escalation + 1, scenarioProject.year - scenario.startYear));
                }
                else {
                    return scenarioProject.project.estBudget;
                }
            }
        }
    }
    console.error('No valid conditions met to calculate the budget.');
    return 0;
};
export const sortAssets = (assignedAssets, showAgeAdjustedCondition) => {
    const getCondition = (asset) => showAgeAdjustedCondition ? asset.ageAdjustedCondition : asset.condition;
    return assignedAssets.sort((a, b) => {
        var _a, _b, _c, _d;
        const conditionA = (_a = getCondition(a)) !== null && _a !== void 0 ? _a : 0;
        const conditionB = (_b = getCondition(b)) !== null && _b !== void 0 ? _b : 0;
        if (conditionB !== conditionA) {
            return conditionB - conditionA;
        }
        if (a.name !== b.name) {
            return a.name.localeCompare(b.name, 'en-u-kn-true');
        }
        if (a.projectedLifeRemaining === undefined &&
            b.projectedLifeRemaining !== undefined) {
            return 1;
        }
        else if (a.projectedLifeRemaining !== undefined &&
            b.projectedLifeRemaining === undefined) {
            return -1;
        }
        return ((_c = a.projectedLifeRemaining) !== null && _c !== void 0 ? _c : 0) - ((_d = b.projectedLifeRemaining) !== null && _d !== void 0 ? _d : 0);
    });
};
export function handleInformationTabSubmit(hookFormSubmit, onSubmit) {
    return (event) => __awaiter(this, void 0, void 0, function* () {
        let successful = true;
        const onFailureWatch = () => __awaiter(this, void 0, void 0, function* () {
            successful = false;
        });
        yield hookFormSubmit(onSubmit, onFailureWatch)(event);
        return successful;
    });
}
export const sortByProjectSummary = (projects, getProjectSummary) => {
    const sortedProjects = projects.sort((a, b) => {
        const projectA = getProjectSummary(a);
        const projectB = getProjectSummary(b);
        if (projectA.priorityScore !== projectB.priorityScore) {
            return (projectB.priorityScore || 0) - (projectA.priorityScore || 0);
        }
        if (projectA.projectTypeName !== projectB.projectTypeName) {
            return projectA.projectTypeName.localeCompare(projectB.projectTypeName, 'en-u-kn-true');
        }
        return (projectB.estimatedBudget || 0) - (projectA.estimatedBudget || 0);
    });
    return sortedProjects;
};
export const calculateEscalatedBudget = (project, escFactor, year, scenarioStartYear) => {
    if (project.estimatedBudget) {
        if (!escFactor) {
            return project.estimatedBudget;
        }
        else {
            if (project.quoteDate) {
                const projectDateYear = new Date(project.quoteDate).getFullYear();
                if (year > +projectDateYear) {
                    const escIndex = year - +projectDateYear;
                    return parseFloat((project.estimatedBudget *
                        Math.pow(escFactor.escalation + 1, escIndex)).toFixed(2));
                }
                else {
                    return project.estimatedBudget;
                }
            }
            else {
                if (scenarioStartYear && year - scenarioStartYear > 0) {
                    return (project.estimatedBudget *
                        Math.pow(escFactor.escalation + 1, year - scenarioStartYear));
                }
                else {
                    return project.estimatedBudget;
                }
            }
        }
    }
    console.error('No valid conditions met to calculate the budget.');
    return 0;
};
