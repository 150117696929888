var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { convertIScenarioDetailsToIScenarioSummary } from '@frontend/domain/models/Scenario/ScenarioConversions';
import { useScenarioService } from '@frontend/domain/services/ScenarioService';
import { useEffect, useState } from 'react';
import { convertCreateTaxImpactFormToICreateTaxImpact, convertScenarioFormToIUpdateScenario, } from './ScenarioConversions';
export const useScenario = (orgIdentity, onScenarioUpsert) => {
    const { platformApi } = useDependencies();
    const { scenario: scenarioApi } = useScenarioService({ platformApi });
    const [scenarioList, setScenarioList] = useState([]);
    const [scenario, setScenario] = useState();
    const [isScenarioLoading, setIsScenarioLoading] = useState(false);
    useEffect(() => {
        if (orgIdentity) {
            fetchScenarioList(orgIdentity);
        }
    }, [orgIdentity]);
    const fetchScenarioList = (orgId) => __awaiter(void 0, void 0, void 0, function* () {
        scenarioApi
            .fetchList(orgId)
            .then((response) => {
            if (response) {
                const sortedResponse = sortScenarioList(response);
                setScenarioList(sortedResponse || []);
            }
        });
    });
    const fetchScenario = (scenarioIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsScenarioLoading(true);
        scenarioApi
            .fetchScenario(scenarioIdentity)
            .then((scenario) => {
            setScenario(scenario);
        })
            .finally(() => setIsScenarioLoading(false));
    });
    const updateScenarioList = (newScenario) => {
        const scenarioIndex = scenarioList.findIndex((scenario) => newScenario.id === scenario.scenarioId);
        if (scenarioIndex > 0) {
            scenarioList[scenarioIndex].scenarioName = newScenario.name;
            setScenarioList([...scenarioList]);
        }
        else {
            setScenarioList([
                ...scenarioList,
                convertIScenarioDetailsToIScenarioSummary(newScenario),
            ]);
        }
    };
    const upsertScenario = (scenario) => __awaiter(void 0, void 0, void 0, function* () {
        if (orgIdentity) {
            return scenarioApi
                .createScenario(convertScenarioFormToIUpdateScenario(scenario, orgIdentity))
                .then((createdScenario) => {
                updateScenarioList(createdScenario);
                setScenario(createdScenario);
                onScenarioUpsert(createdScenario);
            });
        }
    });
    const createTaxImpact = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (scenario === null || scenario === void 0 ? void 0 : scenario.id) {
            return scenarioApi
                .setTaxImpact(convertCreateTaxImpactFormToICreateTaxImpact(scenario.id, data))
                .then((createdTaxImpact) => {
                if (createdTaxImpact) {
                    setScenario(Object.assign(Object.assign({}, scenario), { taxImpact: createdTaxImpact }));
                    return Promise.resolve();
                }
                else {
                    return Promise.reject();
                }
            })
                .catch(() => Promise.reject());
        }
    });
    const refreshScenarioAndScenarioList = (lockScenario) => {
        if (scenario) {
            setScenario(Object.assign(Object.assign({}, scenario), { isLocked: lockScenario, taxImpact: undefined }));
            if (scenarioList.length) {
                const scenarioIndex = scenarioList.findIndex((listItem) => scenario.id === listItem.scenarioId);
                scenarioList[scenarioIndex].isLocked = lockScenario;
                const sortedResponse = sortScenarioList(scenarioList);
                setScenarioList([...sortedResponse]);
            }
        }
    };
    const sortScenarioList = (list) => {
        return list.sort((a, b) => {
            if (a.isLocked && !b.isLocked)
                return -1;
            if (!a.isLocked && b.isLocked)
                return 1;
            if (a.scenarioName < b.scenarioName)
                return -1;
            if (a.scenarioName > b.scenarioName)
                return 1;
            return 0;
        });
    };
    const deleteScenario = (scenarioIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        scenarioApi
            .deleteScenario(scenarioIdentity)
            .then(() => {
            setScenario(undefined);
            const updatedScenarioList = scenarioList.filter((scenario) => scenario.scenarioId !== scenarioIdentity);
            setScenarioList(updatedScenarioList);
        })
            .catch((err) => {
            console.error(err);
        });
    });
    return {
        scenarioList,
        scenario,
        isScenarioLoading,
        fetchScenario,
        deleteScenario,
        upsertScenario,
        refreshScenarioAndScenarioList,
        createTaxImpact,
    };
};
