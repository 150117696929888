const containerName = process.env.REACT_APP_FCA_STORAGE_CONTAINER;
const storageAccountName = 'mysiteiqstorage';
const BLOB_STORE_BASE_URL = `https://${storageAccountName}.blob.core.windows.net`;
export const BLOB_STORE_CONTAINER_URL = `${BLOB_STORE_BASE_URL}/${containerName}`;
export const toCleanFileName = (fileName) => {
    const splittedFileName = fileName.split('.');
    const extension = splittedFileName.pop();
    const cleanedName = splittedFileName
        .join('.')
        .replace(/[!*'();:@&=+$,/?%#[\].\\]+|\s+/g, '-');
    return cleanedName + '.' + extension;
};
export const getFileExtension = (fileName) => {
    return fileName.split('.').pop() || '';
};
export const createFileWithUpdatedName = (file, newName) => {
    return new File([file], newName, {
        type: file.type,
        lastModified: file.lastModified,
    });
};
const toContainerPath = (fileName, 
// eslint-disable-next-line unused-imports/no-unused-vars
orgIdentity, type) => {
    // orgIdentity is not needed until we migrate photos to use org folders
    // when reimplementing, add orgIdentity/ before the type
    // leaving orgIdentity in toContainerPath params because the work is already done to utilize it; leaving it allows the only change needing to be done here
    const containerPath = `${type ? type + '/' : ''}${fileName}`;
    return containerPath;
};
export const toBlobStoreContainerUrl = (fileName, orgIdentity) => {
    const resultUrl = `${BLOB_STORE_CONTAINER_URL}/${toContainerPath(fileName, orgIdentity, 'compressed')}`;
    return resultUrl;
};
