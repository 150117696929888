import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Center, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { usePlanService } from '@frontend/domain/services/PlanService';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { ExternalPaths } from '../../config/paths';
import starterImage from '../../images/ScenarioPlanner_baseImage.png';
import { Apps } from '../../objects/CPBaseInterfaces';
import { useAppSelector } from '../../store/hooks';
import { selectOrgApps, selectSelectedOrg } from '../../store/orgSlice';
import { selectAccess } from '../../store/userSlice';
import { AccessDenied } from '../AccessDenied';
import { AssetsTable } from './AssetsTable';
export const MyAssetsPage = () => {
    const { platformApi } = useDependencies();
    const { plan } = usePlanService({ platformApi });
    const currOrg = useAppSelector(selectSelectedOrg);
    const orgApps = useAppSelector(selectOrgApps);
    const cpAccess = useAppSelector(selectAccess);
    const [planAssetDetail, setPlanAssetDetail] = useState();
    const [loading, setLoading] = useState();
    useEffect(() => {
        if (currOrg === null || currOrg === void 0 ? void 0 : currOrg.identity) {
            setLoading(true);
            plan
                .fetchAssets(currOrg.identity)
                .then((response) => {
                if (response) {
                    setPlanAssetDetail(response);
                }
            })
                .finally(() => setLoading(false));
        }
    }, [currOrg === null || currOrg === void 0 ? void 0 : currOrg.identity]);
    const fcaAccess = orgApps === null || orgApps === void 0 ? void 0 : orgApps.some(({ app }) => app === Apps.FCA);
    const navigate = useNavigate();
    return (_jsx(_Fragment, { children: _jsx(PageLayout, Object.assign({ title: 'myAssets', onAddProject: () => navigate(ExternalPaths.getMyOrgProjectsUrl(currOrg === null || currOrg === void 0 ? void 0 : currOrg.id), {
                state: { showModal: true },
            }), onAddScenario: () => navigate(ExternalPaths.getMyOrgScenariosUrl(currOrg === null || currOrg === void 0 ? void 0 : currOrg.id), {
                state: { showModal: true },
            }) }, { children: _jsx(_Fragment, { children: loading || !currOrg || !orgApps ? (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsx(Spinner, { color: appColors.PRIM_BLUE }) }))) : !cpAccess ? (_jsx(AccessDenied, {})) : (_jsx(Box, { children: planAssetDetail && planAssetDetail.assets.length > 0 ? (_jsx(AssetsTable, { planAssetDetail: planAssetDetail })) : (_jsx(Center, Object.assign({ h: '65vh', w: '100%' }, { children: _jsx(Box, Object.assign({ textAlign: 'center' }, { children: fcaAccess ? (_jsxs(VStack, { children: [_jsx(Image, { "aria-label": 'no assets available', h: '325px', sx: { objectFit: 'cover' }, src: starterImage }), _jsxs(Text, { children: ["You do not have any assets! Use the Prioritize --", '>', ' ', "FCA Tool to add assets"] })] })) : (_jsxs(VStack, { children: [_jsxs(Text, { children: [currOrg === null || currOrg === void 0 ? void 0 : currOrg.name, " does not have access to the FCA Tool."] }), _jsx(Text, { children: "Please contact your client concierge." })] })) })) }))) })) }) })) }));
};
