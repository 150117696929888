import axios from 'axios';
import { environment } from '../config/environment';
import FacilityService from './facilityService';
import FeatureFlagService from './featureFlagService';
import OrgService from './orgService';
import PhotoService from './photoService';
import ProjectService from './projectService';
import RestService from './RestService';
import UserService from './userService';
export const apiService = new RestService(axios, environment.apiBase);
export const platformApiService = new RestService(axios, environment.platformApi);
const facilityService = new FacilityService(apiService);
const photoService = new PhotoService(apiService);
const projectService = new ProjectService(apiService);
const userService = new UserService(apiService, platformApiService);
const orgService = new OrgService(apiService, platformApiService);
const featureService = new FeatureFlagService(apiService);
const services = {
    facilityService,
    orgService,
    photoService,
    projectService,
    userService,
    featureService,
};
export default services;
