var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrganizationRole, SystemRole } from '../objects/UserData';
import { usePlatformUserApi } from '../services/api/platformUserApiHook';
import { hasAccess } from '../utils/userRoleUtils';
export const initialUserState = {
    authUser: null,
    userData: { id: 'null' },
    access: false,
};
export const fetchUser = createAsyncThunk('user/fetch', () => __awaiter(void 0, void 0, void 0, function* () {
    const { fetchUser } = usePlatformUserApi();
    return yield fetchUser();
}));
export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.userData = action.payload;
        });
        builder.addCase(fetchUser.rejected, (state) => {
            state.userData = undefined;
        });
    },
});
export const selectUser = (state) => state.user.userData;
export const selectAccess = (state) => {
    // if no org has been selected yet, nothing to have access to
    if (!state.organization.selectedOrg || !state.organization.orgApps) {
        return true;
    }
    else {
        return hasAccess(state.user.userData, state.organization.selectedOrg, state.organization.orgApps);
    }
};
export const getOrgRole = (user, orgId) => {
    var _a, _b;
    const systemRole = user.systemRole;
    if (SystemRole.SUPER_ADMIN === systemRole ||
        SystemRole.ORG_APPROVER === systemRole) {
        return OrganizationRole.LOCAL_ADMIN;
    }
    return (_b = (_a = user.organizationRoles) === null || _a === void 0 ? void 0 : _a.find((role) => role.organizationIdentity === orgId)) === null || _b === void 0 ? void 0 : _b.role;
};
export const projectCanBeEditedBy = (role) => {
    return (OrganizationRole.LOCAL_ADMIN === role ||
        OrganizationRole.MEMBER === role ||
        OrganizationRole.CLIENT_CONCIERGE === role);
};
export const scenarioCanBeAddedBy = (role) => {
    return (OrganizationRole.LOCAL_ADMIN === role ||
        OrganizationRole.MEMBER === role ||
        OrganizationRole.CLIENT_CONCIERGE === role);
};
export const scenarioCanBeEditedBy = (role) => {
    return (OrganizationRole.LOCAL_ADMIN === role ||
        OrganizationRole.MEMBER === role ||
        OrganizationRole.CLIENT_CONCIERGE === role);
};
export const userIsCCPlus = (state) => {
    var _a;
    const user = state.user.userData;
    const systemRole = user === null || user === void 0 ? void 0 : user.systemRole;
    const orgId = (_a = state.organization.selectedOrg) === null || _a === void 0 ? void 0 : _a.identity;
    if (!user || !orgId) {
        return;
    }
    const orgRole = getOrgRole(user, orgId);
    if (systemRole && systemRole !== SystemRole.USER) {
        return true;
    }
    if (orgRole === OrganizationRole.CLIENT_CONCIERGE) {
        return true;
    }
    else {
        return false;
    }
};
export const selectOrgRole = (state) => {
    var _a;
    const user = state.user.userData;
    const orgId = (_a = state.organization.selectedOrg) === null || _a === void 0 ? void 0 : _a.identity;
    if (!user || !orgId) {
        return;
    }
    return getOrgRole(user, orgId);
};
export default userSlice.reducer;
