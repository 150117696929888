import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, HStack, Spacer, Text, useToken, VStack, } from '@chakra-ui/react';
import { FilterInput, FilterInputType, } from '@frontend/design-system/components/Filter/FilterInput';
import { validateRangeValue, } from '@frontend/design-system/components/Inputs/RangeInput';
import React, { useEffect } from 'react';
import { uniqueFieldValuesFromObjectList } from '../../../components/projects/ProjectFilterModal';
import { variants } from '../../../config/theme';
export const getUniqueNumberListSorted = (projects, numberField) => {
    const set = new Set();
    projects.forEach((project) => {
        const value = numberField(project);
        value !== undefined && set.add(value);
    });
    const array = Array.from(set);
    array.sort();
    return Array.from(array);
};
export const getUniqueStringListSorted = (projects, stringField) => {
    const set = new Set();
    projects.forEach((project) => {
        const value = stringField(project);
        value !== undefined && value !== '' && set.add(value);
    });
    const array = Array.from(set);
    array.sort();
    return Array.from(array);
};
const scoringOptions = Array.from({ length: 11 }, (_, index) => index % 2 === 0 ? Math.floor(index / 2).toString() : (index / 2).toFixed(1));
export const SelectProjectsFilter = ({ projects, filterContext, formContext, isEscalated, }) => {
    const [blue] = useToken('colors', ['brand.primBlue']);
    const [border] = useToken('borderStyles', ['border.primary']);
    const { initialFilter, applyFilter } = filterContext;
    const { control, reset, getValues, formState: { isDirty }, trigger, } = formContext;
    useEffect(() => {
        // ensure filter is validated and updated on any input change
        trigger();
        if (isDirty) {
            const data = getValues();
            applyFilter(data);
        }
    }, [isDirty]);
    return (_jsxs(VStack, Object.assign({ w: '100%', h: '100%', alignItems: 'baseline' }, { children: [_jsxs(HStack, Object.assign({ w: 'full' }, { children: [_jsx(Text, Object.assign({ color: blue, fontWeight: 'semibold' }, { children: "Filters" })), _jsx(Spacer, {})] })), _jsxs(VStack, Object.assign({ spacing: 2, h: '100%', maxH: '53vh', overflowY: 'auto', border: border, px: '2px', pt: 3 }, { children: [_jsxs(VStack, Object.assign({ width: '98%', spacing: '10px' }, { children: [_jsx(FilterInput, { inputConfig: {
                                    label: 'Facility',
                                    type: FilterInputType.MULTI_SELECT,
                                    formField: 'facilityName',
                                    options: getUniqueStringListSorted(projects, (project) => project.facilityName).map((item) => ({
                                        label: item,
                                        value: item,
                                    })),
                                }, control: control }), _jsx(FilterInput, { inputConfig: {
                                    label: 'Project Type',
                                    type: FilterInputType.MULTI_SELECT,
                                    formField: 'projectTypeName',
                                    options: getUniqueStringListSorted(projects, (project) => project.projectTypeName).map((item) => ({
                                        label: item,
                                        value: item,
                                    })),
                                }, control: control }), _jsx(FilterInput, { inputConfig: {
                                    label: 'Fund Type',
                                    type: FilterInputType.MULTI_SELECT,
                                    formField: 'plannedFundingTypes',
                                    options: getUniqueStringListSorted(projects, (project) => project.fundingTypeName).map((item) => ({
                                        label: item,
                                        value: item,
                                    })),
                                }, control: control }), _jsx(FilterInput, { inputConfig: {
                                    label: 'Priority',
                                    type: FilterInputType.MULTI_SELECT,
                                    formField: 'priorityRating',
                                    options: getUniqueStringListSorted(projects, (project) => project.priorityRating).map((item) => ({
                                        label: item,
                                        value: item,
                                    })),
                                }, control: control }), _jsx(FilterInput, { inputConfig: {
                                    label: 'Budget',
                                    type: FilterInputType.RANGE,
                                    formField: isEscalated ? 'escalatedBudget' : 'estimatedBudget',
                                    inputControl: {
                                        type: 'number',
                                    },
                                    validate: (value) => {
                                        if (value !== null) {
                                            const range = value;
                                            return validateRangeValue(range);
                                        }
                                    },
                                }, control: control }), _jsx(FilterInput, { inputConfig: {
                                    label: 'Prioritization Criteria',
                                    type: FilterInputType.MULTI_SELECT_AND,
                                    formField: 'criteriaScores',
                                    subField: 'name',
                                    options: uniqueFieldValuesFromObjectList(projects, (project) => project.criteriaScores.map((scores) => scores.name)).map((item) => ({
                                        label: item,
                                        value: item,
                                    })),
                                }, control: control }), _jsx(FilterInput, { inputConfig: {
                                    label: 'Criteria Score',
                                    type: FilterInputType.MULTI_SELECT_AND,
                                    formField: 'criteriaScores',
                                    subField: 'score',
                                    options: [
                                        { label: 'None', value: 'None' },
                                        ...scoringOptions.map((option) => ({
                                            label: option,
                                            value: option,
                                        })),
                                    ],
                                }, control: control })] })), _jsx(Spacer, { height: '20px' }), _jsx(Flex, Object.assign({ position: 'sticky', bottom: '0', w: '100%', minH: '46px', bg: 'white', justify: 'right', align: 'center' }, { children: _jsx(Button, Object.assign({ maxH: '36px', fontSize: '14px', justifySelf: 'left', variant: variants.blueOutlineBtn, onClick: () => {
                                filterContext.clearFilter();
                                reset(initialFilter);
                            } }, { children: "Clear All" })) }))] }))] })));
};
