import { jsx as _jsx } from "react/jsx-runtime";
import { HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { YearContainer } from './YearContainer';
import { ZoomYearTabs } from './ZoomYearTabs';
export const PlanContainer = ({ fundingTypes, scenario, scheduledProjects, unscheduledProjects, setZoomYear, zoomYear, updatePlan, addProjects, removeProject, escalationFactor, fetchScenarioPlan, }) => {
    const [years, setYears] = useState([]);
    const scenarioIsLocked = scenario.isLocked;
    useEffect(() => {
        const yearsTemp = [];
        for (let i = scenario.startYear; i < scenario.startYear + scenario.planDuration; i++) {
            yearsTemp.push(i);
        }
        setYears(yearsTemp);
    }, [scenario]);
    return (_jsx(HStack, Object.assign({ h: '100%', overflowX: 'auto' }, { children: zoomYear ? (_jsx(ZoomYearTabs, { fundingTypes: fundingTypes, isEscalated: scenario.applyEscalationFactor, scheduledProjects: scheduledProjects, unscheduledProjects: unscheduledProjects, updatePlan: updatePlan, years: years, zoomYear: zoomYear, setZoomYear: setZoomYear, scenarioId: scenario.id, scenarioIsLocked: scenarioIsLocked, addProjects: addProjects, removeProject: removeProject, escalationFactor: escalationFactor, scenarioStartYear: scenario.startYear, fetchScenarioPlan: fetchScenarioPlan })) : (years.map((year) => (_jsx(YearContainer, { fundingTypes: fundingTypes, isEscalated: scenario.applyEscalationFactor, scheduledProjects: scheduledProjects, unscheduledProjects: unscheduledProjects, updatePlan: updatePlan, years: years, year: year, zoomYear: zoomYear, setZoomYear: setZoomYear, scenarioId: scenario.id, scenarioIsLocked: scenarioIsLocked, addProjects: addProjects, removeProject: removeProject, escalationFactor: escalationFactor, scenarioStartYear: scenario.startYear, fetchScenarioPlan: fetchScenarioPlan }, year)))) })));
};
