import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/react';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import { convertOrgSummaryToOrganizationSnapshot } from '@frontend/domain/models/Organization/OrganizationConversions';
import { useOrganizationService } from '@frontend/domain/services/OrganizationService';
import { useEffect, useState } from 'react';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import { useAppSelector } from '../../store/hooks';
import { projectCanBeEditedBy, scenarioCanBeAddedBy, scenarioCanBeEditedBy, selectAccess, selectOrgRole, selectUser, } from '../../store/userSlice';
import { ExpandableSidebar } from '../navigation/ExpandableSidebar';
import { PageHeader, } from '../navigation/PageHeader';
export const PageLayout = ({ title, onAddScenario, onAddProject, children, }) => {
    const { platformApi } = useDependencies();
    const { organization } = useOrganizationService({ platformApi });
    const { orgFundingTypes, orgProjectTypes } = usePlannerSettingsContext();
    const { projectList } = useProjectsContext(); // assumes project provider is at app level
    const userCurrent = useAppSelector(selectUser);
    const [userOrgs, setUserOrgs] = useState([]);
    useEffect(() => {
        organization.fetchList().then((response) => {
            if (response.length) {
                const orgs = convertOrgSummaryToOrganizationSnapshot(response).sort((a, b) => a.name.localeCompare(b.name));
                setUserOrgs(orgs);
            }
        });
    }, [userCurrent]);
    const orgRole = useAppSelector(selectOrgRole);
    const cpAccess = useAppSelector(selectAccess);
    // TODO: refactor out to permissions hook
    const canAddProject = orgFundingTypes &&
        orgFundingTypes.length > 0 &&
        orgProjectTypes &&
        orgProjectTypes.length > 0;
    const canAddScenario = orgFundingTypes &&
        orgFundingTypes.length > 0 &&
        projectList.length > 0 &&
        scenarioCanBeAddedBy(orgRole);
    const canEditProject = projectCanBeEditedBy(orgRole);
    const canEditScenario = scenarioCanBeEditedBy(orgRole);
    const getHeaderButtonConfig = () => {
        if (!cpAccess ||
            !organization ||
            (!canEditProject && !canEditScenario) ||
            !onAddProject ||
            !onAddScenario) {
            return undefined;
        }
        else {
            return {
                projectButtonConfig: {
                    label: 'Add Project',
                    onClick: onAddProject,
                    isDisabled: !canAddProject,
                },
                scenarioButtonConfig: {
                    label: 'Add Scenario',
                    onClick: onAddScenario,
                    isDisabled: !canAddScenario,
                },
            };
        }
    };
    return (_jsx(ExpandableSidebar, { children: _jsxs(_Fragment, { children: [_jsx(PageHeader, { title: title, userOrgs: userOrgs, addButtonConfig: getHeaderButtonConfig() }), _jsx(Flex, Object.assign({ flexGrow: 1, flexDir: 'column', py: '20px' }, { children: children }))] }) }));
};
