import { parseCurrencyAsFloat, parseNumberAsString, } from '../conversion/NumberConversions';
export const validateMinMax = (value, min, max) => {
    if (value) {
        const parsedNum = parseCurrencyAsFloat(value);
        if (parsedNum < min || parsedNum > max) {
            return `Must be between ${parseNumberAsString(min)} and ${parseNumberAsString(max)}`;
        }
    }
    return undefined;
};
