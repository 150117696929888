import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, Flex, Heading, HStack, InputGroup, InputLeftAddon, Select, } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { appColors } from '../../config/constants';
import { ExternalPaths } from '../../config/paths';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchOrgApps, selectSelectedOrg, setOrgApps, setSelectedOrg, } from '../../store/orgSlice';
export const SESSION_SCENARIO_ID_KEY = 'scid';
export const PageHeader = ({ title, userOrgs, addButtonConfig, }) => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const currOrg = useAppSelector(selectSelectedOrg);
    const getPageUrl = (orgId) => location.pathname.includes(ExternalPaths.getMyProjectsUrl())
        ? ExternalPaths.getMyOrgProjectsUrl(orgId)
        : location.pathname.includes(ExternalPaths.getMyScenariosUrl())
            ? ExternalPaths.getMyOrgScenariosUrl(orgId)
            : location.pathname.includes(ExternalPaths.getMyAssetsUrl())
                ? ExternalPaths.getMyOrgAssetsUrl(orgId)
                : location.pathname;
    const handleOrgChange = (e) => {
        resetOrgInfo();
        const orgId = e.target.value;
        const url = getPageUrl(orgId);
        navigate(url);
    };
    const resetOrgInfo = () => {
        dispatch(setOrgApps(undefined));
    };
    const getOrganizationOptions = (orgs) => {
        return orgs.map((org, index) => (_jsx("option", Object.assign({ value: org.id }, { children: org.name }), index)));
    };
    const updateSelectedOrg = (oid) => {
        const orgToSelect = userOrgs === null || userOrgs === void 0 ? void 0 : userOrgs.find((org) => org.id === oid);
        if (orgToSelect) {
            dispatch(setSelectedOrg(orgToSelect));
            dispatch(fetchOrgApps(orgToSelect.id));
        }
    };
    useEffect(() => {
        if (userOrgs && userOrgs.length > 0) {
            if (params.oid && (!currOrg || params.oid !== currOrg.id)) {
                updateSelectedOrg(params.oid);
            }
            else if (currOrg === null || currOrg === void 0 ? void 0 : currOrg.id) {
                navigate(getPageUrl(currOrg.id));
            }
            else {
                navigate(getPageUrl(userOrgs[0].id));
            }
        }
    }, [userOrgs, currOrg, params.oid]);
    const hasOrgsToRenderDropdown = userOrgs && userOrgs.length > 0;
    const scenarioButtonConfig = addButtonConfig === null || addButtonConfig === void 0 ? void 0 : addButtonConfig.scenarioButtonConfig;
    const projectButtonConfig = addButtonConfig === null || addButtonConfig === void 0 ? void 0 : addButtonConfig.projectButtonConfig;
    return (_jsx(_Fragment, { children: _jsx(Flex, Object.assign({ as: 'header', minH: '50px', w: '100%' }, { children: _jsxs(HStack, Object.assign({ borderBottom: '1px solid #E6E7E9', justifyContent: 'space-between', w: '100%', h: '100%', p: '5px 40px' }, { children: [_jsx(Heading, Object.assign({ as: 'h1', fontSize: 36, fontWeight: 400, color: appColors.TEXT_SEC_DARK_GRAY, whiteSpace: 'nowrap' }, { children: title })), hasOrgsToRenderDropdown && (_jsxs(Flex, Object.assign({ gap: '40px', my: 5, alignItems: 'center' }, { children: [_jsxs(InputGroup, { children: [_jsx(InputLeftAddon, Object.assign({ borderLeftRadius: '8px', color: 'white', background: appColors.PRIM_BLUE }, { children: "Organization" })), _jsxs(Select, Object.assign({ "data-testid": 'org-select', minW: '239px', onChange: handleOrgChange, value: (currOrg === null || currOrg === void 0 ? void 0 : currOrg.id) || '', borderLeftRadius: 0 }, { children: [_jsx("option", Object.assign({ disabled: true, value: '' }, { children: "Select Organization" })), userOrgs && getOrganizationOptions(userOrgs)] }))] }), !projectButtonConfig && !scenarioButtonConfig ? (_jsx(_Fragment, {})) : (_jsxs(ButtonGroup, Object.assign({ variant: 'outline', colorScheme: 'primBlue', spacing: 5, alignItems: 'center', isDisabled: !currOrg }, { children: [projectButtonConfig && (_jsx(Button, Object.assign({ "aria-label": 'add new project', leftIcon: _jsx(AddIcon, {}), w: '50%', h: '40px', onClick: projectButtonConfig.onClick, isDisabled: projectButtonConfig.isDisabled }, { children: projectButtonConfig.label }))), scenarioButtonConfig && (_jsx(Button, Object.assign({ "aria-label": 'add new scenario', leftIcon: _jsx(AddIcon, {}), w: '50%', h: '40px', onClick: scenarioButtonConfig.onClick, isDisabled: scenarioButtonConfig.isDisabled }, { children: scenarioButtonConfig.label })))] })))] })))] })) })) }));
};
